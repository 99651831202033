const language = [
	{
		id: 1,
	  lang : "🇺🇸 English",
		
	},
    {
		id: 2,
        lang: "🇪🇸 Spanish",
		
	},
    {
		id: 3,
        lang: "🇫🇷 French",
		
	},
    {
		id: 4,
        lang: "🇩🇪 German",
		
	},
    {
		id: 5,
        lang: "🇨🇳 Chinese",
		
	},
    {
		id: 6,
        lang: "🇯🇵 Japanese",
		
	},
    {
		id: 7,
        lang: "🇰🇷 Korean",
		
	},
    {
		id: 8,
        lang: "🇷🇺 Russian",
		
	},
    {
		id: 9,
        lang: "🇮🇹 Italian",
		
	},
    {
		id: 10,
        lang: "🇵🇹 Portuguese",
		
	},

    {
		id: 11,
        lang: "🇳🇱 Dutch",
		
	},
    {
		id: 12,
        lang: "🇸🇦 Arabic",
		
	},
    {
		id:13,
        lang: "🇹🇷 Turkish",
		
	},
    {
		id: 14,
        lang: "🇵🇱 Polish",
		
	},
    {
		id: 15,
        lang: "🇸🇪 Swedish",
		
	},
    {
		id: 16,
        lang: "🇮🇩 Indonesian",
		
	},
    {
		id: 17,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 18,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 19,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 20,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 21,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 22,
	  lang : "🇺🇸 English",
		
	},
    {
		id: 23,
        lang: "🇪🇸 Spanish",
		
	},
    {
		id: 24,
        lang: "🇫🇷 French",
		
	},
    {
		id: 25,
        lang: "🇩🇪 German",
		
	},
    {
		id: 26,
        lang: "🇨🇳 Chinese",
		
	},
    {
		id: 27,
        lang: "🇯🇵 Japanese",
		
	},
    {
		id: 28,
        lang: "🇰🇷 Korean",
		
	},
    {
		id: 29,
        lang: "🇷🇺 Russian",
		
	},
    {
		id: 30,
        lang: "🇮🇹 Italian",
		
	},
    {
		id: 31,
        lang: "🇵🇹 Portuguese",
		
	},

    {
		id: 32,
        lang: "🇳🇱 Dutch",
		
	},
    {
		id: 33,
        lang: "🇸🇦 Arabic",
		
	},
    {
		id:34,
        lang: "🇹🇷 Turkish",
		
	},
    {
		id: 35,
        lang: "🇵🇱 Polish",
		
	},
    {
		id: 36,
        lang: "🇸🇪 Swedish",
		
	},
    {
		id: 37,
        lang: "🇮🇩 Indonesian",
		
	},
    {
		id: 38,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 39,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 40,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 41,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 42,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 43,
	  lang : "🇺🇸 English",
		
	},
    {
		id: 44,
        lang: "🇪🇸 Spanish",
		
	},
    {
		id: 45,
        lang: "🇫🇷 French",
		
	},
    {
		id: 46,
        lang: "🇩🇪 German",
		
	},
    {
		id: 47,
        lang: "🇨🇳 Chinese",
		
	},
    {
		id: 48,
        lang: "🇯🇵 Japanese",
		
	},
    {
		id: 49,
        lang: "🇰🇷 Korean",
		
	},
    {
		id: 50,
        lang: "🇷🇺 Russian",
		
	},
    {
		id: 51,
        lang: "🇮🇹 Italian",
		
	},
    {
		id: 52,
        lang: "🇵🇹 Portuguese",
		
	},

    {
		id: 53,
        lang: "🇳🇱 Dutch",
		
	},
    {
		id: 54,
        lang: "🇸🇦 Arabic",
		
	},
    {
		id:55,
        lang: "🇹🇷 Turkish",
		
	},
    {
		id: 56,
        lang: "🇵🇱 Polish",
		
	},
    {
		id: 57,
        lang: "🇸🇪 Swedish",
		
	},
    {
		id: 58,
        lang: "🇮🇩 Indonesian",
		
	},
    {
		id: 59,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 60,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 61,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 62,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 63,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 64,
	  lang : "🇺🇸 English",
		
	},
    {
		id: 65,
        lang: "🇪🇸 Spanish",
		
	},
    {
		id: 66,
        lang: "🇫🇷 French",
		
	},
    {
		id: 67,
        lang: "🇩🇪 German",
		
	},
    {
		id: 68,
        lang: "🇨🇳 Chinese",
		
	},
    {
		id: 69,
        lang: "🇯🇵 Japanese",
		
	},
    {
		id: 70,
        lang: "🇰🇷 Korean",
		
	},
    {
		id: 71,
        lang: "🇷🇺 Russian",
		
	},
    {
		id: 72,
        lang: "🇮🇹 Italian",
		
	},
    {
		id: 73,
        lang: "🇵🇹 Portuguese",
		
	},

    {
		id: 74,
        lang: "🇳🇱 Dutch",
		
	},
    {
		id: 75,
        lang: "🇸🇦 Arabic",
		
	},
    {
		id:76,
        lang: "🇹🇷 Turkish",
		
	},
    {
		id: 77,
        lang: "🇵🇱 Polish",
		
	},
    {
		id: 78,
        lang: "🇸🇪 Swedish",
		
	},
    {
		id: 79,
        lang: "🇮🇩 Indonesian",
		
	},
    {
		id: 80,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 81,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 82,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 83,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 84,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 85,
	  lang : "🇺🇸 English",
		
	},
    {
		id: 86,
        lang: "🇪🇸 Spanish",
		
	},
    {
		id: 87,
        lang: "🇫🇷 French",
		
	},
    {
		id: 88,
        lang: "🇩🇪 German",
		
	},
    {
		id: 89,
        lang: "🇨🇳 Chinese",
		
	},
    {
		id: 90,
        lang: "🇯🇵 Japanese",
		
	},
    {
		id: 91,
        lang: "🇰🇷 Korean",
		
	},
    {
		id: 92,
        lang: "🇷🇺 Russian",
		
	},
    {
		id: 93,
        lang: "🇮🇹 Italian",
		
	},
    {
		id: 94,
        lang: "🇵🇹 Portuguese",
		
	},

    {
		id: 95,
        lang: "🇳🇱 Dutch",
		
	},
    {
		id: 96,
        lang: "🇸🇦 Arabic",
		
	},
    {
		id:97,
        lang: "🇹🇷 Turkish",
		
	},
    {
		id: 98,
        lang: "🇵🇱 Polish",
		
	},
    {
		id: 99,
        lang: "🇸🇪 Swedish",
		
	},
    {
		id: 100,
        lang: "🇮🇩 Indonesian",
		
	},
    {
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
	{
		id: 101,
        lang: "🇩🇰 Danish",
		
	},
    {
		id: 102,
        lang: "🇳🇴 Norwegian",
		
	},
    {
		id: 103,
        lang: "🇫🇮 Finnish",
		
	},
    {
		id: 104,
        lang: "🇬🇧 English (UK)",
		
	},
    {
		id: 105,
        lang: "🇮🇳 Hindi",
		
	},
];

export default language;
