const data = [
	{
		id: 1,
	   image: "https://orimon.ai/images/clients/euruni.png",
		
	},
    {
		id: 2,
	   image: "https://orimon.ai/images/clients/dripalia.png",
		
	},
    {
		id: 3,
	   image: "https://orimon.ai/images/clients/dmi.png",
		
	},
    {
		id: 4,
	   image: "https://orimon.ai/images/clients/convotech.png",
		
	},
    {
		id: 5,
	   image: "https://orimon.ai/images/clients/border-tech-sol.png",
		
	},
    {
		id: 6,
	   image: "https://orimon.ai/images/clients/ClearSK.png",
		
	},
    {
		id: 7,
	   image: "https://orimon.ai/images/clients/2betech.webp",
		
	},
    {
		id: 8,
	   image: "https://orimon.ai/images/clients/imaginism-studios.png",
		
	},
    {
		id: 9,
	   image: "https://orimon.ai/images/clients/hire-designers.png",
		
	},
    {
		id: 10,
	   image: "https://orimon.ai/images/clients/hippo.png",
		
	},

    {
		id: 11,
	   image: "https://orimon.ai/images/clients/mvct.png",
		
	},
    {
		id: 12,
	   image: "https://orimon.ai/images/clients/pickedIn.avif",
		
	},
    {
		id:13,
	   image: "https://orimon.ai/images/clients/prestige-estate-services.webp",
		
	},
    {
		id: 14,
	   image: "https://orimon.ai/images/clients/rightway.webp",
		
	},
    {
		id: 15,
	   image: "https://orimon.ai/images/clients/shapeshift.webp",
		
	},
    {
		id: 16,
	   image: "https://orimon.ai/images/clients/sinalite.png",
		
	},
    {
		id: 17,
	   image: "https://orimon.ai/images/clients/sobag.png",
		
	},
    {
		id: 18,
	   image: "https://orimon.ai/images/clients/stadia.webp",
		
	},
    {
		id: 19,
	   image: "https://orimon.ai/images/clients/tti-logo.png",
		
	},
    {
		id: 20,
	   image: "https://orimon.ai/images/clients/tvs-logo.png",
		
	},
    {
		id: 21,
	   image: "https://orimon.ai/images/clients/wela-logo.png",
		
	},
];

export default data;
